<template>
    <mercur-card class="fill full-height-layout mb-3 mx-4">
        <grid-header :quickSearch.sync="filters.search">
            Commercial Products
            <template slot="actions">
                <mercur-button
                    :to="addCommercialProductRoute"
                    class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus"></i>
                    New Commercial Product
                </mercur-button>
            </template>
        </grid-header>

        <div class="border fill full-height-layout mt-2" >
            <data-table
                class="fill full-height-layout border"
                ref="grid"
                :url="url"
                :options="options"
                :quickSearch="filters.search"
            ></data-table>
        </div>
    </mercur-card>
</template>

<script>
import DataTable from '@/components/DataTable'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'

export default {
    name: 'CommercialProducts',
    components: { DataTable, GridHeader },
    data () {
        return {
            options: {
                columns: {
                    'Commercial Product Name': {
                        field: 'commercialProductName',
                        sortable: true,
                    },
                    'Product Configuration Template Name': {
                        field: 'productConfigurationTemplateName',
                        sortable: true,
                    },
                    'Created Date': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                    'commercialProductId': {
                        field: 'commercialProductId',
                        hide: true,
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-edit',
                        text: 'Edit',
                        onClick: (data) => {
                            this.$router.push({
                                name: 'CommercialProductEdit',
                                params: {
                                    supplierId: this.supplierId,
                                    commercialProductId: data.data.commercialProductId,
                                },
                            })
                        },
                    },
                    {
                        icon: 'fas fa-undo',
                        text: 'Revoke',
                        onClick: ({ data }) => {
                            this.revokeCommercialProduct(data)
                        },
                    },
                ],

                quickSearchColumns: ['productConfigurationTemplateName'],
            },
            url: CONFIG.API.ROUTES.SUPPLIERS.COMMERCIAL_PRODUCTS.LIST.replace('{supplierId}', this.supplierId),
            filters: {
                search: '',
            },
        }
    },
    computed: {
        addCommercialProductRoute () {
            return {
                name: 'CommercialProductAdd',
                params: {
                    supplierId: this.supplierId,
                },
            }
        },
    },
    methods: {
        revokeCommercialProduct (data) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.COMMERCIAL_PRODUCTS.REVOKE.replace('{supplierId}', this.supplierId).replace('{commercialProductId}', data.commercialProductId)
            this.addJob(url)
            this.$api.post(url).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Revoke Commercial product done successfully.`,
                })
                this.$refs.grid.refreshGrid()
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Revoke Commercial product failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
                this.showAggregateInfoOpen = false
            }).finally(() => {
                this.finishJob(url)
            })
        },
    },
}
</script>
